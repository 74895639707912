<template>
  <v-layout column style="width: 100%">
    <MinMainEditorToolbar
      @undo="onUndo"
      @redo="onRedo"
      @clear="onClear"
    ></MinMainEditorToolbar>
    <ScarsEditorToolbar></ScarsEditorToolbar>
    <HelperEditorToolbar></HelperEditorToolbar>
  </v-layout>
</template>

<script>
export default {
  components: {
    MinMainEditorToolbar: () =>
      import("@/components/editors/toolbars/MinMainEditorToolbar"),
    ScarsEditorToolbar: () =>
      import("@/components/editors/toolbars/ScarsEditorToolbar"),
    HelperEditorToolbar: () =>
      import("@/components/editors/toolbars/HelperEditorToolbar"),
  },
  methods: {
    onUndo() {
      this.$emit("undo");
    },
    onRedo() {
      this.$emit("redo");
    },
    onClear() {
      this.$emit("clear");
    },
  },
};
</script>